<template>
<section id="blog">
       <div class="py-12"></div>
        <v-container class="text-center">
          <h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center pt-10">Meet the Team</h2>
           <div
                class="mb-4 font-italic font-weight-light headline text-center"                
              >Our talented and amazing team deliver incredible results</div>   

          <v-row>
            <v-col
              v-for="({ icon, name, text, title, src, fun_src, hover_text }, i) in services"
              :key="i"
              cols="12"
              md="6"
              lg="4"              
            >
             <v-hover
                v-slot:default="{ hover }"
                open-delay="200"
              >
              <v-card
                class="py-6 px-4"
                outlined
                :elevation="hover ? 5 : 0"                
              >
                <v-theme-provider dark>
                  <div>
                    <v-avatar
                      :color="hover ? 'success' : 'primary'"                      
                    >
                      <v-img :src="hover ? getImgUrl(fun_src) : getImgUrl(src)" :alt="name"></v-img>
                    </v-avatar>
                  </div>
                </v-theme-provider>

                <v-card-title
                  class="justify-center font-weight-black text-uppercase"
                  v-text="name"
                ></v-card-title>

                <v-card-text
                  class="subtitle-1"                 
                >
                 {{hover ? hover_text : text}}
                </v-card-text>
              </v-card>
                </v-hover>
            </v-col>
          </v-row>
        </v-container>

        <div class="py-12"></div>
      </section>
</template>

<script>

export default {
  name: 'Services',

  components: {
  },

    methods: {
    getImgUrl(img) {
      return require("../assets/" + img);
    }
  },

  data: () => ({
   
        services: [
          {
            src: "team_steve.jpg",
            fun_src: "team_jughead.jpg",
            name: 'Steve Bezuidenhout',
            text: 'Founder and CEO',
            hover_text: 'Steve has owned and operated businesses in both the UK and South Africa and has 25 years international consulting experience with software and business intelligence. He has worked within the public sector (NHS), retail, shipping, logistics and financial services industries and has extensive experience in the area of software development, data analytics and business intelligence arena.',
          },
          {
            src: "team_steve.jpg",
            fun_src: "team_jughead.jpg",
            name: 'Nash Harrielall',
            text: 'Business Intelligence Team Lead / Data Architect',
            hover_text: 'Nash is an experienced senior developer with over 18 years international consulting experience in software development and data analytics. He has worked on major projects around the African continent and has specific industry experience in manufacturing, financial services, FMCG and hospitality.',
          },
          {
           src: "team_steve.jpg",
           fun_src: "team_jughead.jpg",
            name: 'Clint Stedman',
            text: 'Senior Software Architect',
            hover_text: 'Clint is a seasoned senior software architect and developer with extensive experience developing applications for windows and the web for financial services and IOT (internet of things). He holds 4 separate Microsoft certifications',
          },
            {
            src: "team_steve.jpg",
            fun_src: "team_jughead.jpg",
            name: 'Donheaven Perumal',
            text: 'BI Developer',
            hover_text: 'Don is a full stack Microsoft BI developer with extensive experience in the financial services and contact centre industry.',
          },
          {
            src: "team_steve.jpg",
            fun_src: "team_jughead.jpg",
            name: 'Megandran Thambiran',
            text: 'Junior BI Developer',
            hover_text: 'Megandran is a Microsoft certified BI developer with experience in financial services and payroll.',
          },
          {
            src: "team_steve.jpg",
            fun_src: "team_jughead.jpg",
            name: 'Nicky Bezuidenhout',
            text: 'Marketing and Admin Manager',
            hover_text: 'Nicky is an experienced marketer.',
          },
        ],
       
      }
  ),
};
</script>