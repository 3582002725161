import Vue from "vue";
import Router from 'vue-router';
import Home from "@/components/Home";
import Team from "@/components/Team";
import Blog from "@/components/Blog";
import Partners from "@/components/Partners";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "/blog",
      name: "blog",
      component: Blog
    },
    {
      path: "/team",
      name: "team",
      component: Team
    } ,  
    {
        path: "/partners",
        name: "partners",
        component: Partners
      } ,   
  ]
});
