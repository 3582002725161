<template>
<section id="blog">
        <div class="py-12"></div>

        <v-container>
          <h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center pt-10">Blog</h2>

           <v-responsive
            class="mx-auto mb-12"
            width="300"
          >          
            <v-divider></v-divider>
          </v-responsive>

          <v-row>
            <v-col
              v-for="({ src, text, title, link }, i) in articles"
              :key="i"
              cols="12"
              md="4"
            >
              <v-img
                :src="src"
                class="mb-4"
                height="275"
                max-width="100%"
              ></v-img>

              <h3
                class="font-weight-black mb-4 text-uppercase"
                v-text="title"
              ></h3>

              <div
                class="title font-weight-light mb-5"
                v-text="text"
              ></div>

              <v-btn
                class="ml-n4 font-weight-black"
                text
                :href="link"
              >
                Continue Reading
              </v-btn>
            </v-col>
          </v-row>
        </v-container>

        <div class="py-5"></div>
      </section>
</template>


<script>

export default {
  name: 'Blog',      

  data: () => ({      
        articles: [
          {
            src: 'https://images.unsplash.com/photo-1423784346385-c1d4dac9893a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80',
            title: 'Why data visualization is important for your business',
            text: 'A recent IBM study stated that 90% of all data ever generated was created within the last 2 years. Organizations are generating more data each month and find it increasingly difficult to pull this data together in meaningful ways',
            link: 'https://www.linkedin.com/pulse/why-data-visualization-important-your-business-steve-bezuidenhout/?trackingId=CVmFGRZQhaIF2CKv0pv9tA%3D%3D'
          },
          {
            src: 'https://images.unsplash.com/photo-1475938476802-32a7e851dad1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80',
            title: '8 Steps to Ensure a Successful BI Strategy.',
            text: 'How can BI fulfill its true potential within your organisation? We have used these 8 key principles to ensure that BI has been a catalyst for positive change and delivered on its value proposition. ',
            link: 'https://www.linkedin.com/pulse/why-data-visualization-important-your-business-steve-bezuidenhout/?trackingId=CVmFGRZQhaIF2CKv0pv9tA%3D%3D'
          },
          {
            src: 'https://images.unsplash.com/photo-1416339442236-8ceb164046f8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1892&q=80',
            title: 'Small changes, big difference',
            text: 'Vestibulum in dictum velit, in rhoncus nibh. Maecenas neque libero, interdum a dignissim in, aliquet vitae lectus. Phasellus lorem enim, luctus ut velit eget.',
            link: 'https://www.linkedin.com/pulse/why-data-visualization-important-your-business-steve-bezuidenhout/?trackingId=CVmFGRZQhaIF2CKv0pv9tA%3D%3D'
          },
        ]
  })
}

</script>