<template>
     <section
        id="services"
        class="grey lighten-3"
      >
        <div class="py-6"></div>

        <v-container class="text-center">
          <h2 class="display-2 font-weight-bold mb-3">OUR SERVICES</h2>

          <v-responsive
            class="mx-auto mb-5"
            width="300"
          >          
            <v-divider></v-divider>
          </v-responsive>

          <v-row>
            <v-col
              v-for="({ icon, title, text }, i) in services"
              :key="i"
              cols="12"
              md="6"
              lg="4"              
            >
             <v-hover
                v-slot:default="{ hover }"
                open-delay="200"
              >
              <v-card
                class="py-6 px-4"
                outlined
                :elevation="hover ? 5 : 0"                
              >
                <v-theme-provider dark>
                  <div>
                    <v-avatar
                      :color="hover ? 'success' : 'primary'"
                      size="88"
                    >
                      <v-icon
                        large
                        v-text="icon"
                      ></v-icon>
                    </v-avatar>
                  </div>
                </v-theme-provider>

                <v-card-title
                  class="justify-center font-weight-black text-uppercase"
                  v-text="title"
                ></v-card-title>

                <v-card-text
                  class="subtitle-1"
                  v-text="text"
                >
                </v-card-text>
              </v-card>
                </v-hover>
            </v-col>
          </v-row>
        </v-container>

        <div class="py-12"></div>
      </section>
</template>

<script>

export default {
  name: 'Services',

  components: {
  },

    methods: {
    getImgUrl(img) {
      return require("../assets/" + img);
    }
  },

  data: () => ({
   
        services: [
          {
            icon: 'mdi-chart-bar',
            title: 'Business Intelligence',
            text: 'We provide BI solutions and development support to a variety of clients in different industries. Our experienced team of developers are ready to assist on your BI projects.',
          },
          {
            icon: 'mdi-camera-timer',
            title: 'REAL-TIME INSIGHTS',
            text: 'Real-time insights, via dashboards and best-of-breed analytics tools, across all devices, puts your business in your hands regardless of location.',
          },
          {
            icon: 'mdi-tablet-dashboard',
            title: 'DASHBOARDS',
            text: 'From the boardroom to the engine room of your business, our dashboards will create visibility of customised key metrics, and generate conversation through compelling visualisations.',
          },
            {
            icon: 'mdi-database-check',
            title: 'Data Management',
            text: 'We assist companies to organise, integrate and leverage their data more effectively. Services include data architecture, data integration, data warehousing and cloud migration',
          },
          {
            icon: 'mdi-account-group',
            title: 'Outsource Solutions',
            text: 'Do you require a BI team on demand? We can provide fully managed outsourced services as well as short term deployments to add capacity for key projects.',
          },
          {
            icon: 'mdi-code-braces-box',
            title: 'BESPOKE SOFTWARE',
            text: 'Do you need a specialised business application? We offer bespoke software solutions ranging from simple web apps to complex scenario planning and forecasting applications.',
          },
        ],
       
      }
  ),
};
</script>