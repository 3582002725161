<template>
<v-app-bar
      app
      color="white"
      height="100" 
    >    
        <v-img
          contain
          max-height="90%"
          :src="getImgUrl('cogni_logo.png')"
          
          
        ></v-img>  
        <v-spacer></v-spacer>  
        <span class="d-none d-md-block">
            <v-row>
                <v-col
                v-for="({ text, anchor }, i) in menuItems"
                :key="i">

                <!-- First check if the current route is on landing page (or wherever your #foo is) then set the click function for it. -->
                <v-btn class="px-2" text v-if='$route.path == "/"' @click="() => $vuetify.goTo(anchor)">{{text}}</v-btn>

                <!-- If route.path is not at "/" then we call the router push to redirect first to Landing and then scroll. -->
                <v-btn text v-else  @click="() => $router.push('/' + anchor)">{{text}}</v-btn>

                </v-col>

            </v-row>
        <!-- <v-btn      
              
                    text
                    @click="$vuetify.goTo('#hero')"
                  >home</v-btn>     
       <v-btn
                    text
                    @click="$vuetify.goTo('#about-us')"
                  >about us</v-btn>  
                  <v-btn
                    text
                    @click="$vuetify.goTo('#services')"
                  >services</v-btn>    
                    <v-btn
                    text
                    @click="$vuetify.goTo('#clients')"
                  >clients</v-btn>        
                  <v-btn
                    text
                    @click="$vuetify.goTo('#contact-us')"
                  >contact</v-btn>    -->
        </span>       
    </v-app-bar>
</template>

<script>


export default {
  name: 'Navbar',
   data: () => ({
   
        menuItems: [
          {text: "Home", anchor: "#hero" },
          {text: "About Us", anchor: "#about-us" },
          {text: "Services", anchor: "#services" },
          {text: "Clients", anchor: "#clients" },
          {text: "Contact", anchor: "#contact-us" },
        ]
   }),

  components: {
  },

    methods: {
    getImgUrl(img) {
      return require("../assets/" + img);
    }
  }
}
  </script>