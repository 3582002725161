<template>
     <section id="about-us">
        <div class="py-8"></div>

        <v-container class="text-center">
          <h2 class="display-2 font-weight-bold mb-3">ABOUT US</h2>

         <v-responsive
            class="mx-auto mb-12"
            width="300"
          >          
            <v-divider></v-divider>
          </v-responsive>

          <v-responsive
            class="mx-auto title font-weight-light mb-8 text-justify px-3"
            max-width="1200"
          >
            Today, all businesses are data businesses. Data makes business possible and is crucial to companies of all sizes. At Cognizance, we are passionate about helping businesses to tell their data story. 
            Most companies need to unite around some key metrics or KPI's to drive themselves forward. These key metrics will often need to feed down into all parts of the organisation. 
          </v-responsive>

            <v-responsive
            class="mx-auto title font-weight-light mb-8 text-justify px-3"
            max-width="1200"
          >
            We work with businesses to effectively manage and leverage their data assets to support growth and empower decision making.         
            Our custom built dashboards and reporting platforms enable you to communicate information in a way that is accessible to everyone, transforming complex manual reports and spreadsheets into actionable insights.
          </v-responsive>

          <v-responsive
            class="mx-auto title font-weight-light mb-12 text-justify px-3"
            max-width="1200"
          >
            Cognizance is a Microsoft Gold Partner in data analytics. Microsoft’s Gold competencies demonstrate the best-in-class capability for specific Microsoft Services. Having been awarded this title means that the company has attained the highest standards of Microsoft's widely-recognized partnership program.
          </v-responsive>
        
                   <v-img
          contain
          max-height="180px"
          :src="getImgUrl('msgold_logo.png')"></v-img>

          <div class="mb-8"></div>

           <!-- <v-btn
            color="grey"
             @click="$vuetify.goTo('#contact-us')"
            outlined
            large
            class="mt-5 mx-5"
          >
            <span class="grey--text text--darken-1 font-weight-bold">
              Book a meeting
            </span>
          </v-btn>
          <v-btn
            color="grey"
            to = "/blog"
            outlined
            large
            class="mt-5 mx-5"
          >
            <span class="grey--text text--darken-1 font-weight-bold">
              Read Our Blog
            </span>            
          </v-btn> 
          <v-btn
            color="grey"
             to="/team"
            outlined
            large
            class="mt-5 mx-5"
          >
            <span class="grey--text text--darken-1 font-weight-bold">
              Meet the Team
            </span>
          </v-btn>
          <v-btn
            color="grey"
            to="/partners"
            outlined
            large
            class="mt-5 mx-5"
          >
            <span class="grey--text text--darken-1 font-weight-bold">
              Our Partners
            </span>
          </v-btn>
          -->
        </v-container>

        <div class="py-12"></div>
      </section>

</template>

<script>
    export default {

  data: () => ({   	
           
  }),
  methods: {
    getImgUrl(img) {
      return require("../assets/" + img)
    }
  }
        }

</script>