<template>
	<v-sheet id="contact-us" color="#333333" dark tag="section" tile>
		<div class="py-5"></div>

		<v-container>
			<h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center">Talk to Us</h2>
			<div class="title font-weight-bold mb-3 text-center">Here's our data, so we can chat about yours!</div>
			<v-responsive class="mx-auto mb-12" width="300">
				<v-divider></v-divider>
			</v-responsive>

			<v-row>
				<v-col cols="12" md="3">
					<v-img :src="getImgUrl('bulb.png')" class="mb-4"></v-img>
				</v-col>
				<v-col cols="12" md="4">
					<v-row>
						<v-col cols="2" align="right">
							<v-icon>mdi-map-marker</v-icon>
						</v-col>
						<v-col cols="10" class="subtitle-1">
							Dias House
							<br />Fairway Green <br />3 Abrey Road <br />Kloof <br />3640
							<br />
							South Africa
						</v-col>
						<v-col cols="2" align="right">
							<v-icon>mdi-phone</v-icon>
						</v-col>
						<v-col cols="10" class="subtitle-1">+27 79 890 2093</v-col>

						<v-col cols="2" align="right">
							<v-icon>mdi-email</v-icon>
						</v-col>
						<v-col cols="10" class="subtitle-1">support@cogni.co.za</v-col>

						<v-responsive class="mx-auto my-5 px-12">
							<v-divider></v-divider>
						</v-responsive>

						<v-col cols="12" align="center" class="pl-12">
							<v-btn class="ma-2" text dark icon large href="https://www.facebook.com/cognizancebi/">
								<v-icon>mdi-facebook</v-icon>
							</v-btn>

							<v-btn class="ma-2" text dark icon large href="https://www.linkedin.com/company/cognizance-data/">
								<v-icon>mdi-linkedin</v-icon>
							</v-btn>

							<v-btn class="ma-2" text dark icon large href="https://twitter.com/cognizancedata">
								<v-icon>mdi-twitter</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-col>
				<v-col cols="12" md="4">
					<v-theme-provider light>
						<v-form ref="form" v-model="valid" :lazy-validation="lazy">
							<v-text-field
								:disabled="disabled"
								flat
								dense
								label="Name*"
								v-model="name"
								solo
								:error-messages="nameErrors"
								required
								@input="$v.name.$touch()"
								@blur="$v.name.$touch()"
							></v-text-field>
							<v-text-field
								:disabled="disabled"
								flat
								dense
								v-model="email"
								solo
								:error-messages="emailErrors"
								label="E-mail"
								required
								@input="$v.email.$touch()"
								@blur="$v.email.$touch()"
							></v-text-field>
							<v-textarea
								:disabled="disabled"
								flat
								dense
								label="Message*"
								v-model="message"
								solo
								:error-messages="messageErrors"
								required
								@input="$v.message.$touch()"
								@blur="$v.message.$touch()"
							></v-textarea>
							<v-btn :disabled="disabled" color="accent" large @click="sendMail">Submit</v-btn>
						</v-form>
					</v-theme-provider>

					<div v-if="mailSent" class="pt-3 subtitle-1 text--white">
						<v-icon class="px-3">{{ statusIcon }}</v-icon
						>{{ statusMessage }}
					</div>
				</v-col>
			</v-row>
		</v-container>

		<div class="py-12"></div>
		<div class="py-5"></div>
	</v-sheet>
</template>

<script>
import axios from 'axios';
import { validationMixin } from 'vuelidate';
import { required, maxLength, email } from 'vuelidate/lib/validators';

export default {
	mixins: [validationMixin],

	validations: {
		name: { required, maxLength: maxLength(30) },
		email: { required, email },
		message: { required },
	},
	data: () => ({
		valid: true,
		mailSent: false,
		name: '',
		email: '',
		message: '',
		disabled: false,
		statusMessage: '',
		statusIcon: '',
		lazy: false,
	}),
	computed: {
		messageErrors() {
			const errors = [];
			if (!this.$v.message.$dirty) return errors;
			!this.$v.message.required && errors.push('Message is required');
			return errors;
		},
		nameErrors() {
			const errors = [];
			if (!this.$v.name.$dirty) return errors;
			!this.$v.name.maxLength && errors.push('Name must be at most 30 characters long');
			!this.$v.name.required && errors.push('Name is required.');
			return errors;
		},
		emailErrors() {
			const errors = [];
			if (!this.$v.email.$dirty) return errors;
			!this.$v.email.email && errors.push('Must be valid e-mail');
			!this.$v.email.required && errors.push('E-mail is required');
			return errors;
		},
	},

	methods: {
		getImgUrl(img) {
			return require('../assets/' + img);
		},
		validate() {
			this.$refs.form.validate();
		},
		sendMail() {
			this.disabled = true;

			this.$v.$touch();
			if (this.$v.$invalid) {
				this.statusMessage = 'Validation Errors occurred';
				this.statusIcon = 'mdi-alert-circle';
				this.disabled = false;
			} else {
				this.statusMessage = 'Sending mail...';
				const postData = {
					subject: 'Cognizance Website Enquiry',
					content: `Name: ${this.name}\nEmail: ${this.email}\nMessage: ${this.message}`,
					source: 'Cognizance.Web',
				};

				let axiosConfig = {
					headers: {
						'Content-Type': 'application/json;charset=UTF-8',
						requestOriginKey: 'AB1BB7D0-B6C4-4BC4-BD11-3E5A48336C0D',
					},
				};

				axios
					.post('https://cognizance-api-web-app.azurewebsites.net/api/web/sendemail', postData, axiosConfig)
					.then((response) => {
						console.log(response.status);
						if (response.status == 200) {
							this.statusMessage = 'Thanks for reaching out. We will be in contact soon :)';
							this.statusIcon = 'mdi-send';
						} else {
							this.statusMessage = 'Something went wrong :( Please contact us via other means.';
							this.statusIcon = 'mdi-alert-circle';
						}
						this.mailSent = true;
					})
					.catch(function(error) {
						console.log('error ' + error);
					});
			}
		},
	},
};
</script>

<style>
/* Set the size of the div element that contains the map */
#map {
	height: 400px; /* The height is 400 pixels */
	width: 100%; /* The width is the width of the web page */
}
</style>
