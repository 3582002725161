<template>
  <v-content>
    <section id="hero">
      <v-row no-gutters>
        <v-img
          :min-height="'calc(100vh - ' + $vuetify.application.top + 'px)'"
          :src="getImgUrl('hero.jpg')"
        >
          <v-theme-provider dark>
            <v-container fill-height>
              <v-row align="center" class="white--text mx-auto" justify="center">
                <v-col class="white--text text-center" cols="12" tag="h1">
                  <span
                    class="font-weight-light"
                    :class="[$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2']"
                  >SEE YOUR BUSINESS</span>

                  <br />

                  <span
                    :class="[$vuetify.breakpoint.smAndDown ? 'display-3': 'display-4']"
                    class="font-weight-black"
                  >CLEARLY</span>
                  <br />
                  <span class="title">Business Intellligence and Data Analytics</span>
                </v-col>

                <v-btn class="align-self-end" fab outlined @click="$vuetify.goTo('#about-us')">
                  <v-icon>mdi-chevron-double-down</v-icon>
                </v-btn>
              </v-row>
            </v-container>
          </v-theme-provider>
        </v-img>
      </v-row>
    </section>

    <About />

    <!-- <section id="stats">
      <v-parallax
        :height="$vuetify.breakpoint.smAndDown ? 700 : 500"
        :src="getImgUrl('data_bg.jpg')"
      >
        <v-container fill-height>
          <v-row class="mx-auto">
            <v-col cols="12">
              <div class="text-center">
                <div
                  class="display-2 font-italic font-weight-black mb-2"
                >"We are surrounded by data but starved for insights"</div>
                <div class="headline font-weight-black mb-4">Jay Baer</div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-parallax>
    </section> -->

    <Services />

    <!-- <Products /> -->

    <Clients />

    <Contact />
  </v-content>
</template>

<script>
import Contact from "../components/Contact";
import Clients from "../components/Clients";
import Services from "../components/Services";
//import Products from "../components/Products";
import About from "../components/About";

export default {
  name: "App",

  components: {
    Contact,
    Clients,
    Services,
    //Products,
    About
  },

  methods: {
    getImgUrl(img) {
      return require("../assets/" + img);
    }
  },

  mounted() {



    var section = this.$router.currentRoute.hash.replace("#", "");
    var element = window.document.getElementById(section)
    var headerOffset = -25;
    var elementPosition = element.offsetTop;
    var offsetPosition = elementPosition - headerOffset;
    document.documentElement.scrollTop = offsetPosition;
    document.body.scrollTop = offsetPosition; // For Safari



    // var element = window.document.getElementById(section)
    // const offset = 45;
    // const bodyRect = document.body.getBoundingClientRect().top;
    // const elementRect = element.getBoundingClientRect().top;
    // const elementPosition = elementRect - bodyRect;
    // const offsetPosition = elementPosition - offset;
    // if (section)
    //   this.$nextTick(() =>
    //     window.scrollTo({
    //       top: offsetPosition,
    //       behavior: "smooth"
    //     })
    //   );

  },

  data: () => ({
    features: [
      {
        icon: "mdi-chart-bar",
        title: "Business Intelligence",
        text:
          "We provide BI solutions and development support to a variety of clients in different industries. Our experienced team of developers are ready to assist on your BI projects."
      },
      {
        icon: "mdi-camera-timer",
        title: "REAL-TIME INSIGHTS",
        text:
          "Real-time insights, via dashboards and best-of-breed analytics tools, across all devices, puts your business in your hands regardless of location."
      },
      {
        icon: "mdi-tablet-dashboard",
        title: "DASHBOARDS",
        text:
          "From the boardroom to the engine room of your business, our dashboards will create visibility of customised key metrics, and generate conversation through compelling visualisations."
      },
      {
        icon: "mdi-database-check",
        title: "Data Wrangling",
        text:
          "We tame data! Bring us your complicated data issues and we will provide simple and agile solutions to warehouse, move and report on your business data."
      },
      {
        icon: "mdi-account-group",
        title: "Outsource Solutions",
        text:
          "Do you require a BI team on demand? We can provide fully managed outsourced services as well as short term deployments to add capacity for key projects."
      },
      {
        icon: "mdi-code-braces-box",
        title: "BESPOKE SOFTWARE",
        text:
          "Do you need a specialised business application? We offer bespoke software solutions ranging from simple web apps to complex scenario planning and forecasting applications."
      }
    ]
  })
};
</script>
