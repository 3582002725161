<template>
<v-sheet
        id="clients"
        color="#666666"
        dark
        tag="section"
        tile
      >
        <div class="py-5" color="#333333"></div>

        <v-container>
          <h2 class="display-2 font-weight-bold mb-12 text-uppercase text-center">Our Clients</h2>
          
          <v-responsive
            class="mx-auto mb-8"
          >          
            <v-divider></v-divider>
          </v-responsive>
          <v-row>
            <v-col
              v-for="({ src, text,name, title }, i) in clients"
              :key="i"
              cols="12"
              md="6"
              
            >
            <v-responsive
            class="mx-auto mb-5 mt-5"   
            max-width="750"         
          >   
              <div
                class="mb-4 font-italic font-weight-light headline"
                v-text="text"
              ></div>
              <div class="d-flex flex-row">
              <span>
              <v-avatar min-height="80px">
              <v-img
              :src="getImgUrl(src)"              
                class="mb-4"
                height="50px"
              ></v-img>
              </v-avatar>
              </span>
              <span>
               <div
                class="title font-weight-light ml-3"
                v-text="name"
              ></div>
              <div
                class="subtitle-1 font-weight-light mb-3 ml-3"
                v-text="title"
              ></div>
              </span>
              </div>
            </v-responsive>
            </v-col>
          </v-row>        
        </v-container>

        <div class="py-4"></div>
        <div>
        <v-row class="justify-center align-center">
            <v-col
              v-for="({src, height}, i) in logos"
              :key="i"
              cols="12"
              sm="6"
              lg="4"    
              class="mb-8"        
            >
        <v-img          
          contain
          :max-height="height"
          :src="getImgUrl(src)"></v-img>
            </v-col>
        </v-row>
          </div>

        <div class="py-12"></div>
    </v-sheet>
</template>

<script>
    export default {

  data: () => ({   	
       clients: [
          {
            src: 'guy.jpg',
            name: 'Guy Groom',
            title: 'Chief Solutions Officer - The Unlimited MVNO',
            text: 'The reporting and insights provided by the Cognizance team have provided us operational efficiencies, improved our overall customer experience and satisfaction while giving us a competitive advantage.',
          },
          {
            src: 'jesse.jpg',
            name: 'Jesse Kotze',
            title: 'Chief Operating Officer - eDeaf',
            text: "Cognizance developed a series of dashboards and data visualizations that have added real value to the way in which we report to the Government's Jobs Fund initiative.",
          }          
        ],
         logos:
        [ 
          {src: "Edeaf_logo.png", height: "60px"},
          {src: "tu_logo.png", height: "60px"},         
          //{src: "netvendor_logo.png", height: "50px"},        
          {src: "arborcare_logo.png", height: "80px"},
          {src: "DLM_logo.png", height: "60px"},
          {src: "crew_logo.png", height: "60px"},         
          //{src: "dcc_logo.png", height: "80px"},
          //{src: "energy_logo.png", height: "60px"},
          {src: "deafinition_logo.png", height: "55px"},
          
        ]
  }),
  methods: {
    getImgUrl(img) {
      return require("../assets/" + img)
    }
  }
        }

</script>